import React, { useEffect } from 'react';
import TabMenu from 'component/TabMenu';

import AsideHeader from './AsideHeader';
import ClassTab from './ClassTab';
import RecentPlayTab from './RecentPlayTab';
import BookMarkTab from './Bookmark';
import { useAxios } from 'common/hook/useAxiosHook';
import productApi from 'api/product';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import contentsApi from 'api/contents';
import { useTranslation } from 'react-i18next';

const CourseViewAside = ({ product }) => {
  const { t } = useTranslation('viedoDetailPage');

  const { productId, lectureId } = useParams();
  const token = useSelector((state) => state.user.token);

  const [{ data: courseListData }, fetchCourseList] = useAxios(
    productApi.getCoursesOfProduct,
    [],
    true
  );

  const [{ data: historyListData }, fetchHistoryList] = useAxios(
    contentsApi.getHistoryList,
    [],
    true
  );

  useEffect(() => {
    fetchCourseList(productId, token);
    fetchHistoryList(token);
    return () => {};
  }, [fetchCourseList, fetchHistoryList, productId, lectureId, token]);

  return (
    <>
      {product && <AsideHeader product={product} />}

      <TabMenu
        //menuList={[t('classTab.title'), t('recentPlayTab.title'), 'Bookmark']}
        menuList={[t('classTab.title'), t('recentPlayTab.title')]}
        contentsList={[
          <ClassTab courseListData={courseListData} />,
          <RecentPlayTab historyListData={historyListData} />,
          // <BookMarkTab BookMarkListData={historyListData} />,
        ]}
      />
    </>
  );
};

export default CourseViewAside;
